import React from "react";
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-react-intl";

import Layout from "../layouts/Layout";
import Seo from "../components/Seo/Seo";

const NotFoundPage = ({ intl }) => (
  <Layout>
    <Seo
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <section className="section homepage-content">
      <div className="container">
        <h1>
          <FormattedMessage id="notfound.header" />
        </h1>
        <p>
          <FormattedMessage id="notfound.description" />
        </p>
        <button onClick={() => navigate(-1)}> Go Back</button>
      </div>
    </section>

  </Layout>
);

export default injectIntl(NotFoundPage);
